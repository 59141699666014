import React, { useState } from 'react'
import QuestionRenderer from './QuestionRenderer'

function SubmissionForm1({ questions, subtaskId, onAnswerChange }) {
  const [answers, setAnswers] = useState({})

  // const parseQuestions = questions
  // ? JSON.parse(questions || "[]")
  // : [];
  const parseQuestions = questions
    ? typeof questions === 'string'
      ? JSON.parse(questions || "[]")
      : questions // Use directly if it's already an object or array
    : [];

  const handleAnswerChange = (questionIndex, answer) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [subtaskId]: {
        ...(prevAnswers[subtaskId] || {}),
        [questionIndex]: answer
      }
    }))

    // Call the onAnswerChange prop with the subtaskId, questionIndex, and answer
    onAnswerChange(subtaskId, questionIndex, answer)
  }

  return (

    <div className='max-w-screen-xl w-full mx-auto justify-center items-center p-4 flex flex-col gap-5 '>
      {parseQuestions.map((question, index) => (
        <div className='w-full  md:w-[600px]'>
          <QuestionRenderer
            key={`question-${question.questionIndex}`}
            question={question}
            answer={
              (answers[subtaskId] &&
                answers[subtaskId][question.questionIndex]) ||
              ''
            }
            onAnswerChange={answer =>
              handleAnswerChange(question.questionIndex, answer)
            }
          />
        </div>
      ))}
    </div>
  )
}

export default SubmissionForm1
