import React, { useEffect, useRef, useState } from "react";

const OtpModal = ({
  phoneNumber,
  onVerify,
  onClose,
  isVerifyingOtp,
  onResendOtp,
}) => {
  const [otp, setOtp] = useState(Array(6).fill("")); // For 6-digit OTP input
  const [timer, setTimer] = useState(120); // 1-minute timer
  const dialogRef = useRef(null);
  const [resendAvailable, setResendAvailable] = useState(false);
  useEffect(() => {
    // Automatically show the modal when the component is mounted
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }
    restartCountdown();

    return () => {
      // clearInterval(countdown); // Clear the timer on unmount
      if (dialogRef.current) {
        dialogRef.current.close();
      }
    };
  }, []);

  const restartCountdown = () => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          // handleClose();
          setResendAvailable(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };
  const handleVerify = () => {
    const otpValue = otp.join(""); // Combine the OTP digits into a single string
    onVerify(otpValue); // Pass the OTP to the parent component
  };

  const handleClose = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
    }
    onClose(); // Trigger the onClose callback
  };

  const handleChange = (value, index) => {
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically move to the next input box
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };
  const handleResendOtp = () => {
    onResendOtp(); // Call the resend OTP API
    setResendAvailable(false);
    setTimer(120);
    setOtp(Array(6).fill(""));
  };
  return (
    <dialog ref={dialogRef} id="otp_modal" className="modal">
      <div className="modal-box bg-white text-gray-800 rounded-lg shadow-lg p-6 relative">
        {/* Close Button (✕) */}
        <form method="dialog">
          <button
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            onClick={handleClose}
          >
            ✕
          </button>
        </form>

        {/* Modal Content */}
        <h3 className="font-bold text-lg text-center mb-4">Enter OTP</h3>
        <p className="text-center text-gray-600 mb-6">
          We have sent an OTP to your phone number:{" "}
          <strong>{phoneNumber}</strong>
        </p>

        {/* OTP Input Boxes */}
        <div className="flex justify-center gap-2 mb-6">
          {otp.map((digit, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              value={digit}
              onChange={(e) => handleChange(e.target.value, index)}
              maxLength="1"
              className="w-12 h-12 text-center bg-gray-200 text-black font-semibold border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 text-lg"
            />
          ))}
        </div>

        {/* Timer */}
        {/* {!resendAvailable && <p className="text-center text-gray-500 mb-4">
          Time remaining: <span className="font-bold">{Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}</span>
        </p>} */}

        {resendAvailable ? (
          // Resend OTP Button (only visible when the timer reaches 0)
          <div className="flex justify-center items-center">
            <a
              href="#"
              className="text-boomcolor font-bold hover:text-gray-600"
              onClick={handleResendOtp}
            >
              Resend OTP
            </a>
          </div>
        ) : (
          <p className="text-center text-gray-500 mb-4">
            Time remaining:{" "}
            <span className="font-bold">
              {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, "0")}
            </span>
          </p>
        )}

        {/* Modal Actions */}
        <div className="modal-action flex justify-center items-center">
          <button
            className="btn bg-boomcolor text-white hover:bg-boomcolor"
            onClick={handleVerify}
          >
            {isVerifyingOtp ? "Verifying..." : "Verify OTP"}
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default OtpModal;
