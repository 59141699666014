import React, { useEffect, useState } from "react";
import "./AppRoutes.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import SideNavbar from "./SideNavbar";
import SubmissionForm1 from "./SubmissionForm1";
import axios from "axios";
import PersonalDetail from "./PersonalDetail";

import SubmissionForm2 from "./SubmissionForm2";
import AfterSubmit from "./AfterSubmit";
import OtpModal from "./OtpModal";
import toast from "react-hot-toast";

function AppRoutes() {
  const [taskTitle, setTaskTitle] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [brandName, setBrandName] = useState("");
  const [subtaskInfos, setSubtaskInfos] = useState([]);
  const [currentSubtask, setCurrentSubtask] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state for the "Submit" button
  const [sessionId, setSessionId] = useState("");
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [subtaskAnswers, setSubtaskAnswers] = useState({});
  const [taskType, setTaskType] = useState("");

  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);

  const pathSegments = window.location.pathname.split("/");
  // const code = pathSegments[2]

  const [code, setCode] = useState("");

  const location = useLocation();

  // Check if the current path matches the /:code format
  const isCodePage = location.pathname === `/${code}`;
  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");

    // Case 1: If the URL is in the format /:code (e.g., /8Bvm8k)
    if (pathSegments.length === 2) {
      setCode(pathSegments[1]);
    }
    // Case 2: If the URL is in the format /bs/:code/15 (e.g., /bs/8Bvm8k/15)
    else if (pathSegments.length === 4) {
      setCode(pathSegments[2]);
    } else {
      console.log("Unknown URL format");
    }
  }, []);
  console.log(code);
  useEffect(() => {
    if (code) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/getLinkData`, { code })
        .then((response) => {
          setSubtaskInfos(response.data.data.subtask_infos);
          setCurrentSubtask(response.data.data.subtask_infos[0]);
          setTaskType(response.data.data.taskType);
          setTaskTitle(response.data.data.taskTitle);
          setBrandName(response.data.data.brand_info.displayName);
          setBrandLogo(response.data.data.brand_info.brandLogo);
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [code]);

  const handleNavItemClick = (subtaskId) => {
    const subtask = subtaskInfos.find(
      (subtask) => subtask.subtaskId === subtaskId
    );
    setCurrentSubtask(subtask);
  };

  const handlePersonalDetailChange = (newDetails) => {
    setPersonalDetails(newDetails);
  };

  const handleAnswerChange = (subtaskId, questionIndex, answer) => {
    if (subtaskId === null && questionIndex === null && answer === null) {
      setSubtaskAnswers({});
    } else {
      setSubtaskAnswers((prevAnswers) => ({
        ...prevAnswers,
        [subtaskId]: {
          ...prevAnswers[subtaskId],
          [questionIndex]: answer,
        },
      }));
    }
  };

  const handleSubmit = () => {
    if (
      (!personalDetails.name ||
        !personalDetails.email ||
        !personalDetails.phoneNumber) &&
      (taskType === 1 || taskType === 3)
    ) {
      alert("Please fill all the personal details before submitting.");
      navigate(`/${code}/personal-detail`);
      return;
    }

    if (taskType === 3) {
      setIsLoading(true);
      // Trigger the send OTP API
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/send-otp`, {
          phone: personalDetails.phoneNumber,
          email: personalDetails.email,
          code: code,
        })
        .then((response) => {
          console.log("OTP sent successfully:", response.data);
          toast.success("OTP sent successfully!");
          // Extract and store session ID
          const sessionId = response.data.sessionId;
          setSessionId(sessionId);

          // Open OTP modal
          setIsLoading(false); // Remove loading state
          setOtpModalOpen(true);
        })
        .catch((error) => {
          console.error(error.response.data.message);
          toast.error(error.response.data.message);
          setIsLoading(false); // Remove loading state
        });
      return;
    }
    // Submission logic for taskType === 1
    performSubmission();
  };
  //!

  const performSubmission = () => {
    const personalId = pathSegments[3];

    // Find the first subtask that is not filled
    const unfilledSubtask = subtaskInfos.find((subtaskInfo) => {
      // const submissionForm = subtaskInfo.subtask_submission_form.submissionForm;
      // const submissionForm = subtaskInfo
      //   ? JSON.parse(subtaskInfo.subtask_submission_form.submissionForm || "[]")
      //   : [];
      // const currentSubtaskAnswers = subtaskAnswers[subtaskInfo.subtaskId] || {};

      const submissionForm = subtaskInfo
        ? typeof subtaskInfo.subtask_submission_form.submissionForm === "string"
          ? JSON.parse(
              subtaskInfo.subtask_submission_form.submissionForm || "[]"
            )
          : subtaskInfo.subtask_submission_form.submissionForm
        : [];
      const currentSubtaskAnswers = subtaskAnswers[subtaskInfo.subtaskId] || {};

      return !submissionForm.every(
        (question) =>
          currentSubtaskAnswers[question.questionIndex] !== undefined
      );
    });

    function isCurrentSubtaskFilled() {
      console.log("subtaskInfos", subtaskInfos);
      console.log("currentSubtask", currentSubtask);

      const currentSubtaskInfo = subtaskInfos.find(
        (subtaskInfo) => subtaskInfo.subtaskId === currentSubtask?.subtaskId
      );
      console.log("currentSubtaskInfo", currentSubtaskInfo);

      if (!currentSubtaskInfo) return false;

      // Ensure submissionForm is an array
      let submissionForm;
      if (typeof currentSubtaskInfo.subtask_submission_form === "string") {
        // If it's a string, parse it
        submissionForm = JSON.parse(
          currentSubtaskInfo.subtask_submission_form || "[]"
        );
      } else {
        // If it's already an object or array, use it directly
        submissionForm = currentSubtaskInfo.subtask_submission_form || [];
      }

      const currentSubtaskAnswers =
        subtaskAnswers[currentSubtask?.subtaskId] || {};

      return (
        Array.isArray(submissionForm.submissionForm) &&
        submissionForm.submissionForm.every(
          (question) =>
            currentSubtaskAnswers[question.questionIndex] !== undefined
        )
      );
    }

    if (unfilledSubtask && taskType === 1) {
      navigate(`/${code}/${unfilledSubtask.subtaskTitle.toLowerCase()}`);
      setCurrentSubtask(unfilledSubtask);
      return;
    }
    console.log("isCurrentSubtaskFilled,", isCurrentSubtaskFilled());

    if (!isCurrentSubtaskFilled() && taskType === 2) {
      alert("Please fill all the fields before submitting.");
      return;
    }

    // Prepare submission data
    let submissionData;
    if (taskType === 1) {
      submissionData = {
        code: code,
        personalDetails: personalDetails,
        taskType3: false,
        answers: Object.entries(subtaskAnswers).map(
          ([subtaskId, subtaskAnswers]) => {
            const subtaskInfo = subtaskInfos.find(
              (info) => info.subtaskId === Number(subtaskId)
            );
            const submissionForm = subtaskInfo
              ? subtaskInfo.subtask_submission_form.submissionForm
              : [];
            // const submissionForm = subtaskInfo
            //   ? JSON.parse(subtaskInfo.subtask_submission_form.submissionForm || "[]")
            //   : [];
            return {
              subtaskId,
              submissionForm: submissionForm.map((question) => ({
                ...question,
                answer: subtaskAnswers[question.questionIndex] || "",
              })),
            };
          }
        ),
      };
      console.log("submission data", submissionData);
    } else if (taskType === 2) {
      const currentSubtaskInfo = subtaskInfos.find(
        (info) => info.subtaskId === currentSubtask?.subtaskId
      );
      const submissionForm = currentSubtaskInfo
        ? currentSubtaskInfo.subtask_submission_form.submissionForm
        : [];

      // const submissionForm = currentSubtaskInfo
      //   ? JSON.parse(currentSubtaskInfo.subtask_submission_form.submissionForm || "[]")
      //   : [];

      submissionData = {
        code: code,
        taskType3: false,
        answers: [
          {
            subtaskId: currentSubtask?.subtaskId,
            submissionForm: submissionForm.map((question) => ({
              ...question,
              answer:
                subtaskAnswers[currentSubtask?.subtaskId]?.[
                  question.questionIndex
                ] || "",
            })),
          },
        ],
      };
    } else if (taskType === 3 && pathSegments[1] === "bs" && personalId) {
      // Prepare submission data for Task Type 3
      submissionData = {
        code,
        personalId, // Include personalId from the URL
        taskType3: true, // Indicate Task ID 3 submission
        answers: Object.entries(subtaskAnswers).map(
          ([subtaskId, subtaskAnswers]) => {
            const subtaskInfo = subtaskInfos.find(
              (info) => info.subtaskId === Number(subtaskId)
            );
            const submissionForm = subtaskInfo
              ? subtaskInfo.subtask_submission_form.submissionForm
              : [];
            // const submissionForm = subtaskInfo
            //   ? JSON.parse(subtaskInfo.subtask_submission_form.submissionForm || "[]")
            //   : [];
            return {
              subtaskId,
              submissionForm: submissionForm.map((question) => ({
                ...question,
                answer: subtaskAnswers[question.questionIndex] || "",
              })),
            };
          }
        ),
      };
    }

    // Submit the data
    console.log("Submitting data:", submissionData);
    // =====================================================================================

    if (submissionData) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/submitLinkData`, {
          data: JSON.stringify(submissionData),
        })
        .then((response) => {
          console.log("Submission successful:", response.data);
          setIsSubmitted(true);
          setSubtaskAnswers({});
          navigate(`/${code}/thanks`);
        })
        .catch((error) => console.error("Error submitting data:", error));
    }
  };
  // Handle OTP verification
  const handleOtpVerify = (otp) => {
    setIsVerifyingOtp(true); // Set loading state to true
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/verify-otp`, {
        // phone: personalDetails.phoneNumber,
        otp: otp,
        sessionId: sessionId,
      })
      .then((response) => {
        console.log("OTP verified successfully:", response.data);
        // Close the OTP modal
        setOtpModalOpen(false);

        // Submit the data directly and redirect to "boompanda.in"
        const submissionData = {
          code,
          personalDetails,
          brandName: brandName,
        };
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/submit-personal-details-link`,
            { data: JSON.stringify(submissionData) }
          )
          .then(() => {
            console.log("submitted succefully");
            toast.success("Personal Details submitted Successfully!");
            // window.location.href = 'https://boompanda.in';

            setTimeout(() => {
              window.location.href = "https://boompanda.in";
            }, 1000);
          })
          .catch((error) =>
            console.error("Error submitting taskType 3 data:", error)
          );
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        toast.error("Error verifying OTP:", error);
        alert("OTP verification failed. Please try again.");
      })
      .finally(() => {
        setIsVerifyingOtp(false); // Reset loading state
      });
  };

  function RedirectToPersonalDetail() {
    const navigate = useNavigate();
    const { code } = useParams();

    useEffect(() => {
      navigate(`/${code}/personal-detail`);
    }, [navigate, code]);

    return null;
  }

  function NotFound() {
    return (
      <div className=" h-screen flex justify-center items-center p-3">
        <div className="max-w-md shadow-xl rounded-xl bg-white border-2 border-boomcolor p-5 text-center">
          <img
            alt="crypanda"
            src="/crypanda.png"
            className="text-center mx-auto"
          />
          <h1 className="text-4xl py-2">404 - Not Found</h1>
          <p className="text-xl">
            The page you are looking for does not exist.
          </p>
        </div>
      </div>
    );
  }
  console.log("tasktype", taskType);

  return (
    <div className="app-container">
      <SideNavbar
        taskType={taskType}
        subtaskInfos={subtaskInfos}
        onNavItemClick={handleNavItemClick}
        code={code}
      />
      <div className="content-container ">
        <Routes>
          <Route path="*" element={<NotFound />} />
          {/* Task Type 3: Specific to `/bs/:code/:personalid` */}
          {pathSegments[1] === "bs" && (
            <>
              <Route
                path="/bs/:code/:personalid"
                element={
                  <SubmissionForm2
                    taskType={taskType}
                    brandLogo={brandLogo}
                    brandName={brandName}
                    taskTitle={taskTitle}
                    onNavItemClick={handleNavItemClick}
                    subtaskInfos={subtaskInfos}
                    onAnswerChange={(subtaskId, questionIndex, answer) =>
                      handleAnswerChange(subtaskId, questionIndex, answer)
                    }
                    handleSubmit={performSubmission} // Submission logic for Task 3
                  />
                }
              />
              {isSubmitted && (
                <Route
                  path="/:code/thanks"
                  element={<AfterSubmit setIsSubmitted={setIsSubmitted} />}
                />
              )}
            </>
          )}
          {/* {taskType === 1 ? ( */}
          {taskType === 1 || taskType === 3 ? (
            <>
              <Route
                path="/:code/:subtaskTitle"
                element={
                  currentSubtask &&
                  currentSubtask.subtask_submission_form && (
                    <SubmissionForm1
                      questions={
                        currentSubtask.subtask_submission_form.submissionForm
                      }
                      subtaskId={currentSubtask.subtaskId}
                      onAnswerChange={(subtaskId, questionIndex, answer) =>
                        handleAnswerChange(subtaskId, questionIndex, answer)
                      }
                    />
                  )
                }
              />
              <Route
                path="/:code/personal-detail"
                element={
                  <PersonalDetail
                    taskType={taskType}
                    brandLogo={brandLogo}
                    brandName={brandName}
                    taskTitle={taskTitle}
                    details={personalDetails}
                    onChange={handlePersonalDetailChange}
                    onSubmit={handleSubmit}
                    subtaskAnswers={subtaskAnswers}
                  />
                }
              />
              <Route path="/:code" element={<RedirectToPersonalDetail />} />
              {isSubmitted && (
                <Route
                  path="/:code/thanks"
                  element={<AfterSubmit setIsSubmitted={setIsSubmitted} />}
                />
              )}
            </>
          ) : (
            <>
              <Route
                path="/:code"
                element={
                  <SubmissionForm2
                    taskType={taskType}
                    brandLogo={brandLogo}
                    brandName={brandName}
                    taskTitle={taskTitle}
                    onNavItemClick={handleNavItemClick}
                    subtaskInfos={subtaskInfos}
                    onAnswerChange={(subtaskId, questionIndex, answer) =>
                      handleAnswerChange(subtaskId, questionIndex, answer)
                    }
                    handleSubmit={handleSubmit}
                  />
                }
              />
              <Route
                path="/:code/thanks"
                element={<AfterSubmit setIsSubmitted={setIsSubmitted} />}
              />
            </>
          )}
        </Routes>

        {/* {(pathSegments[1] !== 'bs') ? (
          (taskType === 1 || taskType === 3) &&
          !isSubmitted && ( */}
        {pathSegments[1] !== "bs" &&
        (taskType === 1 || taskType === 3) &&
        !isSubmitted ? (
          <button
            onClick={handleSubmit}
            className="btn btn-outline uppercase border-boomcolor rounded-full py-2 px-12 bg-white text-boomcolor hover:text-white hover:bg-boomcolor bottom-7 mb-5"
            disabled={isLoading} // Disable button during loading
          >
            {isLoading ? "Sending OTP..." : "Submit"}
          </button>
        ) : null}

        {/* {taskType === 1 && !isSubmitted && (
          <button
            onClick={handleSubmit}
            className='btn btn-outline uppercase border-boomcolor rounded-full py-2 px-12 bg-white text-boomcolor hover:text-white hover:bg-boomcolor bottom-7 mb-5'
          >
            Submit
          </button>
        )} */}
        {otpModalOpen && (
          <OtpModal
            phoneNumber={personalDetails.phoneNumber}
            onVerify={(otp) => {
              handleOtpVerify(otp); // Pass the OTP for verification
            }}
            onClose={() => {
              setOtpModalOpen(false); // Close the modal
            }}
            isVerifyingOtp={isVerifyingOtp}
            onResendOtp={handleSubmit} // Resend OTP
          />
        )}
      </div>
    </div>
  );
}

export default AppRoutes;
