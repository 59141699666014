import React from "react";
import "./boxStyle.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function DropdownQuestion({ question, answer, onAnswerChange }) {
  const handleSelectChange = (event) => {
    onAnswerChange(event.target.value);
  };

  return (
    <div className="bg-white shadow-lg p-4 rounded-md flex flex-col gap-2.5 md:gap-5">
      <h3 className="font-bold text-lg">
        <span className="p-1.5 px-3 text-base rounded-md bg-boomcolor text-white me-2">
          {question.questionIndex}
        </span>
        {question.questionText}
      </h3>
      <h2>
        {question.questionText}
        {question.mandatory && <span className="text-red-500"> *</span>}
      </h2>
      <form>
        <select
          id="dropdowns"
          className="select border border-gray-300 select-bordered w-full bg-white rounded-full focus:border-boomcolor"
          onChange={handleSelectChange}
          >
          <option value="" disabled selected>
            Select an option
          </option>
          {question.options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </form>
      {/* <FormControl fullWidth variant='standard' margin='normal'>
        <InputLabel></InputLabel>
        <Select
          label='Select'
          placeholder='Select an option'
          value={answer}
          onChange={handleSelectChange}
        >
          {question.options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </div>
  );
}

export default DropdownQuestion;
