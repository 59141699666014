import React from 'react'
import ShortAnswer from './ShortAnswer'
import Paragraph from './Paragraph'
import DateQuestion from './DateQuestion'
import TimeQuestion from './TimeQuestion'
import FileUploadQuestion from './FileUploadQuestion'
import CheckboxQuestion from './CheckboxQuestion'
import DropdownQuestion from './DropdownQuestion'

const QuestionRenderer = ({ question, answer, onAnswerChange }) => {
  switch (question.type) {
    case 'short_answer':
      return (
        <div className=''>
          <ShortAnswer
            question={question}
            answer={answer}
            
            onAnswerChange={onAnswerChange}
          />
        </div>
      )

    case 'paragraph':
      return (
        <Paragraph
          question={question}
          answer={answer}
          onAnswerChange={onAnswerChange}
        />
      )
    case 'date':
      return (
        <DateQuestion
          question={question}
          answer={answer}
          onAnswerChange={onAnswerChange}
        />
      )
    case 'time':
      return (
        <TimeQuestion
          question={question}
          answer={answer}
          onAnswerChange={onAnswerChange}
        />
      )
    case 'file_upload':
      return (
        <FileUploadQuestion
          question={question}
          answer={answer}
          onAnswerChange={onAnswerChange}
        />
      )
    case 'checkbox':
      return (
        <CheckboxQuestion
          question={question}
          answer={answer}
          onAnswerChange={onAnswerChange}
        />
      )
    case 'dropdown':
      return (
        <DropdownQuestion
          question={question}
          answer={answer}
          onAnswerChange={onAnswerChange}
        />
      )
    default:
      return null
  }
}

export default QuestionRenderer
