import React, { useEffect, useState } from 'react'

function PersonalDetail ({
  taskType,
  brandLogo,
  brandName,
  taskTitle,
  details,
  onChange,
  onSubmit,
  subtaskAnswers
}) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: ''
  })

  useEffect(() => {
    setFormData(details)
  }, [details])

  const handleInputChange = e => {
    const { name, value } = e.target
    const newFormData = { ...formData, [name]: value }
    setFormData(newFormData)
    onChange(newFormData)
  }

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(subtaskAnswers) // Pass subtask answers to handleSubmit
  }

  return (
    <div className='flex justify-center items-center gap-4 flex-col p-5 rounded-xl pt-10   max-w-lg w-full'>
      <div className='max-auto text-center w-full space-y-3'>
        <div className='w-full h-full overflow-hidden mx-auto'>
          <img
            src={brandLogo}
            className='w-56 h-36 object-cover mx-auto'
            alt='logo'
          />
        </div>

        <h1 className='text-2xl lg:text-3xl font-medium text-center mx-auto text-gray-700 '>
          {taskTitle}
        </h1>
        <h1 className='text-xl text-gray-700 font-medium'>
          By <span className='text-boomcolor font-semibold'>{brandName}</span>
        </h1>
      </div>
      <div className='mx-auto w-full  flex flex-col justify-center items-center'>
        <h1 className='text-center text-2xl font-semibold text-[#FF0455]'>
        {taskType === "1"
    ? "Who's Submission Are You Adding?"
    : "Fill your basic details to get free product"}
        </h1>
        <div className='w-full mt-5 md:mt-10'>
          <div className='mx-auto w-full sm:max-w-md md:max-w-lg flex flex-col gap-5 items-center justify-center'>
            <input
              type='text'
              name='name'
              value={formData.name}
              onChange={handleInputChange}
              placeholder='Name'
              className='input border-gray-300 focus:border-boomcolor border-2 w-full text-black placeholder:text-gray-400 bg-white'
            />
            <input
              type='email'
              name='email'
              value={formData.email}
              onChange={handleInputChange}
              placeholder='Enter Your Email'
              className='input border-gray-300 focus:border-boomcolor border-2 w-full text-black placeholder:text-gray-400 bg-white'
            />
            <input
              type='tel'
              name='phoneNumber'
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder='Enter Your Phone No'
              className='input border-gray-300 focus:border-boomcolor border-2 w-full text-black placeholder:text-gray-400 bg-white'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalDetail
