import React, { useState } from 'react'
import { Checkbox } from '@mui/material'
import './boxStyle.css'

function CheckboxQuestion ({ question, answer, onAnswerChange }) {
  const [selectedOptions, setSelectedOptions] = useState(answer || [])

  const handleCheckboxChange = (event, option) => {
    let newSelectedOptions
    if (event.target.checked) {
      newSelectedOptions = [...selectedOptions, option]
    } else {
      newSelectedOptions = selectedOptions.filter(opt => opt !== option)
    }
    setSelectedOptions(newSelectedOptions)
    onAnswerChange(newSelectedOptions)
  }

  return (
    <div className='bg-white shadow-lg p-4 rounded-md flex flex-col gap-2.5 md:gap-5'>
      <h3 className='font-bold text-lg'>
        <span className='p-1.5 px-3 text-base rounded-md bg-boomcolor text-white me-2'>
          {question.questionIndex}
        </span>
        {question.questionText}
      </h3>
      <h2>
        {question.questionText}
        {question.mandatory && <span className='text-red-500'> *</span>}
      </h2>
      {question.options.map((option, index) => (
        <div key={index} className='flex flex-col gap-2'>
          <label className='label  cursor-pointer border-b-2 rounded-lg'>
            <span className='label-text text-boomcolor font-bold text-lg'> {option}</span>
            <input
             checked={selectedOptions.includes(option)}
              type='checkbox'
              onChange={event => handleCheckboxChange(event, option)}
              className='checkbox checkbox-secondary  border-2 border-gray-400 '
            />
          </label>
        </div>
      ))}
    </div>
  )
}

export default CheckboxQuestion
